import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=761d4575&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=ts&"
export * from "./Button.vue?vue&type=script&lang=ts&"
import style1 from "./Button.vue?vue&type=style&index=1&id=761d4575&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761d4575",
  null
  
)

export default component.exports